/* Base styling for the opening hours container */
.opening-hours {
  flex: 0 0 300px; /* Fixed width for larger screens */
  padding: 20px; /* Padding inside the container */
  background-color: #ffffff; /* White background for contrast */
  border-radius: 10px; /* Rounded corners for a softer look */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

/* Styling for the heading inside the opening hours container */
.opening-hours h2 {
  font-size: 1.5rem; /* Font size for the heading */
  color: #333; /* Darker color for the heading */
  margin-bottom: 10px; /* Space below the heading */
  text-align: center; /* Center the heading text */
  font-weight: 700; /* Bold font weight for the heading */
}

/* Styling for the paragraphs inside the opening hours container */
.opening-hours p {
  font-size: 1rem; /* Font size for the text */
  line-height: 1.5; /* Line height for better readability */
  color: #555; /* Medium gray color for the text */
  text-align: center; /* Center the text */
}

/* Responsive styling */
@media (max-width: 768px) {
  .opening-hours {
      flex: 1 1 auto; /* Allow the container to grow and shrink on smaller screens */
      width: fit-content; /* Full width with some margin */
      max-width: 500px; /* Maximum width for smaller screens */
      margin: 0 auto; /* Center the container */
      padding: 15px; /* Reduce padding */
  }

  .opening-hours h2 {
      font-size: 1.25rem; /* Adjust font size for smaller screens */
  }

  .opening-hours p {
      font-size: 0.9rem; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .opening-hours {
      width: 100%; /* Full width for very small screens */
      max-width: 100%; /* Ensure it fits within the viewport */
      padding: 10px; /* Further reduce padding */
  }

  .opening-hours h2 {
      font-size: 1.1rem; /* Further reduce font size */
  }

  .opening-hours p {
      font-size: 0.8rem; /* Further reduce font size */
  }
}
