/* ContactSection.css */

/* Main container for the Contact section */
.contact-section {
  display: flex; /* Flexbox for layout */
  flex-direction: column; /* Stacked layout for smaller screens */
  align-items: center; /* Center-align items */
  padding: 40px; /* Padding around the section */
  background-color: #f9f9f9; /* Light background color */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin: 0 auto; /* Center the section with some margin */
  max-width: 1200px; /* Maximum width for large screens */
  gap: 20px; /* Space between elements */
}

/* Styling for the contact details */
.contact-details {
  text-align: center; /* Center-align text */
}

.contact-details h2 {
  font-size: 2rem; /* Larger font size for the heading */
  color: #333; /* Darker color for the heading */
  margin-bottom: 20px; /* Space below the heading */
  font-weight: 700; /* Bold font weight */
}

.contact-details p {
  font-size: 1.1rem; /* Font size for the contact details */
  line-height: 1.6; /* Line height for readability */
  color: #555; /* Medium gray color */
  margin-bottom: 20px; /* Space below the text */
}

/* Styling for the map container */
.map-container {
  width: 100%; /* Full width for responsiveness */
  max-width: 100%; /* Full width for smaller screens */
  border-radius: 10px; /* Rounded corners for the map */
  overflow: hidden; /* Hide overflow to keep rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow around the map */
  margin: 0 auto;
}
.map-container iframe {
  width: 100%; /* Make iframe fill the container width */
  height: 450px; /* Fixed height or you can use 100% for full container height */
  border: 0; /* Remove border to match container style */
  display: block; /* Ensures no inline space is left (alternative to vertical-align fix) */
}

/* Responsive styling */
@media (max-width: 1024px) {
  .contact-section {
    padding: 30px; /* Slightly reduced padding for tablets */
  }

  .contact-details h2 {
    font-size: 1.8rem; /* Adjust heading font size for tablets */
  }

  .contact-details p {
    font-size: 1rem; /* Adjust paragraph font size for tablets */
  }
}

@media (max-width: 768px) {
  .contact-section {
    padding: 20px; /* Further reduce padding on smaller screens */
    gap: 15px; /* Reduce space between elements */
  }

  .contact-details h2 {
    font-size: 1.6rem; /* Adjust heading font size for mobile screens */
  }
  

  .contact-details p {
    font-size: 0.9rem; /* Adjust paragraph font size for mobile screens */
  }

  .map-container {
    max-width: 100%; /* Full width for mobile screens */
    margin: 0 auto; 
  }
}

@media (max-width: 480px) {
  .contact-details h2 {
    font-size: 1.4rem; /* Further adjust heading font size for very small screens */
  }

  .contact-details p {
    font-size: 0.8rem; /* Further adjust paragraph font size for very small screens */
  }
  .map-container{
    max-width: 100vw;
    width: 70%;
    height: 55vh; /* Adjust map height for very small screens */
  }

  .map-container iframe{
    width: 100vw;
    position: absolute;
    left: 0;
    height: 60vh;
}
}