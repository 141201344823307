/* AboutUs.css */

/* Container for the "About Us" section */
.about-us-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #000000, #434343); /* Black gradient background */
    padding: 20px;
    border-radius: 10px;
    position: relative;
    opacity: 0; /* Start as hidden for animation */
    transform: translateX(-50px); /* Start offset for animation */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out; /* Smooth transition */
    will-change: transform, opacity; /* Hint to the browser to optimize these properties */
}

/* Content wrapper for text and logo/icons */
.about-us-content {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1200px; /* Adjust as needed */
}

/* Wrapper for logo and icons */
.logo-and-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-right: 40px; /* Space between logo/icons and text */
}

/* Styling for the logo */
.aboutUs-logo {
    width: 100%; /* Increase the logo size */
    height: auto;
    position: relative;
    z-index: 1; /* Ensure logo is above the icons */
}

/* Text content styling */
.text-content {
    flex: 1;
    text-align: left;
}

/* Title styling */
.about-us-title {
    font-size: 2rem;
    color: #fff;
    margin-bottom: 10px;
}

/* Paragraph text styling */
.about-us-text {
    color: #ddd;
    font-size: 1rem;
    margin-bottom: 20px;
    line-height: 1.5;
}

/* "Mehr Lesen" button styling */
.mehr-lesen-button {
    background-color: #f39c12; /* Button background color */
    color: #fff; /* Text color */
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    transition: background-color 0.3s ease; /* Smooth background color transition */
}

/* Hover effect for button */
.mehr-lesen-button:hover {
    background-color: #e67e22; /* Darker shade on hover */
}

/* Media queries for responsiveness */

/* Tablet screens (max-width: 768px) */
@media (max-width: 768px) {
    .about-us-container {
        padding: 15px; /* Reduce padding */
        flex-direction: column; /* Stack items vertically */
    }

    .about-us-content {
        flex-direction: column; /* Stack content vertically */
        text-align: center; /* Center-align text */
        max-width: 100vw; /*
    }

    .logo-and-icons {
        margin-right: 0; /* Remove margin on smaller screens */
        margin-bottom: 20px; /* Add margin below logo/icons */
    }
    .aboutUs-logo{
        margin: 0 auto;;
    }

    .about-us-title {
        font-size: 1.8rem;
        text-align: center; /* Reduce font size for title */
    }

    .about-us-text {
        font-size: 0.9rem;
        text-align: center; /* Reduce font size for text */
    }

    .mehr-lesen-button {
        font-size: 14px; /* Reduce button font size */
        margin: 0 auto;
    }
}

/* Mobile devices (max-width: 480px) */
@media (max-width: 480px) {
    .about-us-container {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center content horizontally */
        justify-content: center; /* Center content vertically */
        text-align: center; /* Center text inside the container */
        padding: 20px; /* Adjust padding for breathing space */
    }

    .about-us-content {
        display: flex;
        justify-content: center;
        width: 100%; /* Full width for content */
        max-width: 100vw; /* Limit maximum width for readability */
        margin: 0 auto; /* Center content horizontally */
    }

    .aboutUs-logo {
        width: 100%; /* Smaller logo size */
        max-width: 70vw; /* Limit maximum width */
        margin: 0 auto; /* Center logo horizontally */
        margin-left: 10%;
    }

    .about-us-title {
        font-size: 1.5rem; /* Further reduce font size for title */
        margin: 10px 0; /* Space around the title */
    }

    .about-us-text {
        font-size: 0.85rem; /* Further reduce font size for text */
        line-height: 1.4; /* Adjust line height */
        margin: 0 auto; /* Center text vertically */
        margin-bottom: 20px; /* Space below the text */
    }

    .mehr-lesen-button {
        font-size: 12px; /* Further reduce button font size */
        margin: 0 auto; /* Space around the button */
        margin-left: 35%;
        display: inline-block; /* Ensure button is not stretched */
        background-color: #f39c12; /* Example background color */
        color: #ffffff; /* Example text color */
        border: none; /* Remove border */
        border-radius: 5px; /* Rounded corners */
        text-align: center; /* Center text inside the button */
        cursor: pointer; /* Pointer cursor on hover */
    }

    
}

/* Very small devices (max-width: 320px) */
@media (max-width: 320px) {
    .about-us-container {
        padding: 5px; /* Minimal padding */
    }

    .aboutUs-logo {
        width: 100%; /* Even smaller logo size */
        max-width: 100vw /* Limit maximum width */
    }

    .about-us-title {
        font-size: 1.3rem; /* Smaller font size for title */
        margin: 8px 0; /* Space around the title */
    }

    .about-us-text {
        font-size: 0.75rem; /* Smaller font size for text */
        margin: 8px 0; /* Space around the text */
        margin: 0 auto;
        margin-bottom: 10%;
    }

    .mehr-lesen-button {
        font-size: 10px; /* Smallest button font size */
        padding: 5px 10px; /* Minimal padding */
        margin-left: 35%; /* Space around the button */
        margin-bottom: 10%;
    }
}
