/* AboutUsSection.css */

/* Container for the entire About Us section */
.about-us-section {
  display: flex; /* Use flexbox to align text and opening hours side-by-side */
  justify-content: space-between; /* Space out the text and opening hours evenly */
  align-items: flex-start; /* Align items to the start of the container */
  padding: 40px; /* Add padding around the container */
  background-color: #f9f9f9; /* Light background color for the section */
  border-radius: 15px; /* Rounded corners for a softer look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  margin: 20px auto; /* Center the section horizontally */
  max-width: 1200px; /* Maximum width for large screens */
  gap: 40px; /* Space between the two containers */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Styling for the text content */
.text-container {
  flex: 1; /* Allow the text container to grow and shrink */
  padding: 20px; /* Padding inside the text container */
}

.text-container h2 {
  font-size: 2rem; /* Larger font size for the heading */
  color: #333; /* Darker color for the heading */
  margin-bottom: 20px; /* Space below the heading */
  font-weight: 700; /* Bold font weight for the heading */
}

.text-container p {
  font-size: 1.1rem; /* Font size for the paragraph */
  line-height: 1.6; /* Line height for better readability */
  color: #555; /* Medium gray color for the paragraph text */
  margin-bottom: 20px; /* Space below the paragraph */
}

/* Styling for the opening hours */
.opening-hours {
  flex: 0 0 300px; /* Fixed width for the opening hours container */
  padding: 20px; /* Padding inside the opening hours container */
  background-color: #ffffff; /* White background for contrast */
  border-radius: 10px; /* Rounded corners for a softer look */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.opening-hours h2 {
  font-size: 1.5rem; /* Font size for the opening hours heading */
  color: #333; /* Darker color for the heading */
  margin-bottom: 10px; /* Space below the heading */
  text-align: center; /* Center the heading text */
  font-weight: 700; /* Bold font weight for the heading */
}

.opening-hours p {
  font-size: 1rem; /* Font size for the opening hours text */
  line-height: 1.5; /* Line height for better readability */
  color: #555; /* Medium gray color for the text */
  text-align: center; /* Center the opening hours text */
}

/* Hover effect for the opening hours container */
.opening-hours:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2), 0 12px 24px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

/* Responsive styling */
@media (max-width: 1024px) {
  .about-us-section {
      flex-direction: column; /* Stack the containers vertically on smaller screens */
      align-items: center; /* Center-align the items */
  }

  .text-container,
  .opening-hours {
      width: 100%; /* Full width for both containers */
      max-width: 800px; /* Maximum width on smaller screens */
  }
}

@media (max-width: 768px) {
  .about-us-section {
      padding: 20px; /* Reduce padding for smaller screens */
      gap: 20px; /* Reduce gap between containers */
  }

  .text-container h2 {
      font-size: 1.8rem; /* Smaller font size for heading */
  }

  .text-container p {
      font-size: 1rem; /* Smaller font size for paragraph */
  }

  .opening-hours {
      flex: 0 0 auto; /* Allow the opening hours container to adapt its width */
  }

  .opening-hours h2 {
      font-size: 1.3rem; /* Smaller font size for heading */
  }

  .opening-hours p {
      font-size: 0.9rem; /* Smaller font size for text */
  }
}

@media (max-width: 480px) {
  .text-container h2 {
      font-size: 1.5rem; /* Further reduce font size for heading */
  }

  .text-container p {
      font-size: 0.9rem; /* Further reduce font size for paragraph */
  }

  .opening-hours {
      padding: 15px; /* Reduce padding inside the opening hours container */
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1), 0 6px 15px rgba(0, 0, 0, 0.1); /* Adjust shadow for smaller screens */
  }

  .opening-hours h2 {
      font-size: 1.2rem; /* Further reduce font size for heading */
  }

  .opening-hours p {
      font-size: 0.8rem; /* Further reduce font size for text */
  }
}
