/* Besondere.css */

.besondere-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px; /* Increased padding */
    max-width: 1400px; /* Increased max-width */
    margin: 0 auto;
    box-sizing: border-box; /* Ensure padding is included in the width */
}

.dish-image {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding shadow with a bit of offset */
}

.image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
}

.dish-content {
    flex: 1;
    padding: 20px; /* Increased padding */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dish-title {
    font-size: 2.5rem; /* Increased font size */
    margin-bottom: 15px; /* Increased margin */
    color: #333;
    overflow: hidden; /* Ensure no overflow for animation effect */
    white-space: nowrap; /* Prevent text wrapping */
    display: inline-block; /* Ensure inline-block for letter animation */
}

.title-letter {
    display: inline-block;
    margin-right: 0.1em; /* Add margin between letters */
}

.dish-description {
    font-size: 1.5rem; /* Increased font size */
    color: #666;
    margin-bottom: 25px; /* Increased margin */
}

.view-menu-button {
    background-color: #f39c12; /* Button background color */
    color: #fff; /* Text color */
    padding: 15px 30px; /* Increased padding */
    border-radius: 5px;
    text-decoration: none;
    font-size: 18px; /* Increased font size */
    font-weight: bold;
    display: inline-block;
    text-align: center;
    transition: background-color 0.3s ease; /* Smooth color transition */
}

.view-menu-button:hover {
    background-color: #e67e22; /* Darker shade on hover */
}

/* Responsive styling for larger tablets and smaller desktops */
@media (max-width: 1024px) {
    .dish-image {
        padding: 10px; /* Reduce padding */
    }

    .image {
        max-width: 400px; /* Adjust max-width for medium screens */
    }
}

/* Responsive styling for tablets and smaller screens */
@media (max-width: 768px) {
    .besondere-container {
        flex-direction: column; /* Stack image and content vertically */
        align-items: center; /* Center content horizontally */
        padding: 20px; /* Reduce padding */
    }

    .dish-image {
        padding: 10px;
    }

    .image {
        max-width: 90%; /* Allow image to take up more space */
    }

    .dish-title {
        font-size: 2rem; /* Adjust font size for smaller screens */
        text-align: center; /* Center align the title */
    }

    .dish-description {
        font-size: 1.2rem; /* Adjust font size for smaller screens */
        text-align: center; /* Center align the description */
    }

    .view-menu-button {
        font-size: 16px; /* Adjust button font size */
        padding: 10px 20px; /* Adjust button padding */
    }
}

/* Responsive styling for mobile devices */
@media (max-width: 480px) {
    .header {
        height: 60vh; /* Adjust height for smaller screens */
    }
    .dish-title{
        font-size: 1.7em;
    }
    .besondere-container {
        max-width: 100vw;
        padding: 0; /* Further reduce padding */
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Center-align items */
    }

    .special-items-container {
        grid-template-columns: 1fr; /* Single column layout for very small screens */
    }

    .special-item {
        margin: 10px 0; /* Adjust margin for spacing */
    }

    .special-item-image {
        width: 100%; /* Full width for image */
    }
}

@media (max-width: 320px) {
    .header {
        height: 60vh; /* Adjust height for smaller screens */
    }
    .dish-title{
        font-size: 1.4em;
    }
    .besondere-container {
        max-width: 100vw;
        padding: 0; /* Further reduce padding */
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Center-align items */
    }

    .special-items-container {
        grid-template-columns: 1fr; /* Single column layout for very small screens */
    }

    .special-item {
        margin: 10px 0; /* Adjust margin for spacing */
    }

    .special-item-image {
        width: 100%; /* Full width for image */
    }
}
